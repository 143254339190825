<template>
    <section>
        <div v-if="mostrarTitulo" class="my-4 d-block text-center text-sm-left">
          <span class="text-h6 text-uppercase font-weight-bold secondary--text mx-1 mx-sm-8 mx-md-0">
            Creación de usuario
          </span>
        </div>
        <v-card>
          <v-card-title style="gap: 8px;" class="align-center flex-wrap">
            <v-icon>mdi-card-account-details</v-icon>
            <span class="text-uppercase text-subtitle-1 font-weight-bold">Información personal</span>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row no-gutters align="start">
              <v-col class="pr-sm-4 px-0" cols="12" sm="6">
                <v-autocomplete
                  :error-messages="nacionalidadError"
                  :items="ctlNacionalidad"
                  @blur="$v.nacionalidad.$touch()"
                  @input="$v.nacionalidad.$touch()"
                  clearable
                  item-text="nombre"
                  label="Nacionalidad *"
                  no-data-text="Sin datos"
                  outlined
                  placeholder="Seleccione"
                  v-model="nacionalidad"
                  return-object
                />
              </v-col>
              <v-col cols="0" sm="6" />
              <v-col class="pr-sm-4 px-0" cols="12" sm="6">
                <v-text-field
                  :disabled="nacionalidad == null"
                  :error-messages="numeroDocumentoError"
                  :maxLength="docMxLength"
                  @blur="$v.numeroDocumento.$touch()"
                  @click:clear="checkNumeroDocumento()"
                  @input="$v.numeroDocumento.$touch()"
                  @keydown.enter.prevent="consultarUsuario"
                  clearable
                  label="Número de documento *"
                  outlined
                  required
                  v-mask="mask"
                  v-model="numeroDocumento"
                />
              </v-col>
              <v-col class="pl-sm-4 px-0" cols="12" sm="6">
                <v-btn
                  color="btnGreen"
                  :block="$vuetify.breakpoint.xs"
                  class="bgWhite--text text-capitalize"
                  @click="consultarUsuario"
                  :disabled="$v.numeroDocumento.$invalid"
                >
                  Verificar
                </v-btn>
              </v-col>
              <v-col class="pr-sm-4 px-0" cols="12" v-if="!$v.numeroDocumento.$invalid">
                <v-alert
                  border="right"
                  colored-border
                  type="warning"
                  elevation="2"
                  icon="mdi-alert"
                >
                  Si el usuario ya tiene un registro activo dentro del sistema
                  deberá utilizar un correo electrónico diferente para la presente
                  solicitud
                </v-alert>
              </v-col>
              <v-col class="pr-sm-4 px-0" cols="12" sm="6">
                <v-text-field
                  :clearable="!verifyNacionalidad"
                  :error-messages="primerNombreError"
                  :readonly="verifyNacionalidad"
                  @blur="$v.primerNombre.$touch()"
                  @input="$v.primerNombre.$touch()"
                  label="Primer nombre *"
                  maxLength="50"
                  outlined
                  v-model.trim="primerNombre"
                />
              </v-col>
              <v-col class="pl-sm-4 px-0" cols="12" sm="6">
                <v-text-field
                  :clearable="!verifyNacionalidad"
                  :error-messages="segundoNombreError"
                  :readonly="verifyNacionalidad"
                  @blur="$v.segundoNombre.$touch()"
                  @input="$v.segundoNombre.$touch()"
                  label="Segundo nombre"
                  maxLength="50"
                  outlined
                  v-model.trim="segundoNombre"
                />
              </v-col>
              <v-col class="pr-sm-4 px-0" cols="12" sm="6">
                <v-text-field
                  :clearable="!verifyNacionalidad"
                  :error-messages="primerApellidoError"
                  :readonly="verifyNacionalidad"
                  @blur="$v.primerApellido.$touch()"
                  @input="$v.primerApellido.$touch()"
                  label="Primer apellido *"
                  maxLength="50"
                  outlined
                  v-model.trim="primerApellido"
                />
              </v-col>
              <v-col class="pl-sm-4 px-0" cols="12" sm="6">
                <v-text-field
                  :clearable="!verifyNacionalidad"
                  :error-messages="segundoApellidoError"
                  :readonly="verifyNacionalidad"
                  @blur="$v.segundoApellido.$touch()"
                  @input="$v.segundoApellido.$touch()"
                  label="Segundo apellido"
                  maxLength="50"
                  outlined
                  v-model.trim="segundoApellido"
                />
              </v-col>
              <v-col class="pr-sm-4 px-0" cols="12" sm="6">
                <v-text-field
                  v-if="showInput"
                  :clearable="!verifyNacionalidad"
                  :error-messages="apellidoCasadaError"
                  :readonly="verifyNacionalidad"
                  @blur="$v.apellidoCasada.$touch()"
                  @input="$v.apellidoCasada.$touch()"
                  label="Apellido de casada"
                  maxLength="50"
                  outlined
                  v-model.trim="apellidoCasada"
                />
              </v-col>
              <v-col cols="0" sm="6" />
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mt-4">
          <v-card-title style="gap: 8px;" class="align-center flex-wrap">
            <v-icon>mdi-account-circle</v-icon>
            <span class="text-uppercase text-subtitle-1 font-weight-bold">Información de usuario</span>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row no-gutters align="start">
              <v-col class="pr-sm-4 px-0" cols="12" sm="6">
                <v-text-field
                  :error-messages="emailUsuarioError"
                  @blur="$v.emailUsuario.$touch()"
                  @input="$v.emailUsuario.$touch()"
                  clearable
                  label="Correo electrónico *"
                  maxLength="50"
                  outlined
                  v-model.trim="emailUsuario"
                />
              </v-col>
              <v-col class="pl-sm-4 px-0" cols="12" sm="6">
                <v-text-field
                  :error-messages="telefonoUsuarioError"
                  @blur="$v.telefonoUsuario.$touch()"
                  @input="$v.telefonoUsuario.$touch()"
                  clearable
                  label="Teléfono *"
                  maxLength="8"
                  outlined
                  v-mask="'#'.repeat(8)"
                  v-model="telefonoUsuario"
                />
              </v-col>
              <v-col class="pr-sm-4 px-0" cols="12" sm="6">
                <v-autocomplete
                  :error-messages="profesionUsuarioError"
                  :items="ctlProfesiones"
                  @blur="$v.profesionUsuario.$touch()"
                  @input="$v.profesionUsuario.$touch()"
                  clearable
                  item-text="nombre"
                  item-value="id"
                  label="Profesión *"
                  no-data-text="Sin datos"
                  outlined
                  return-object
                  v-model="profesionUsuario"
                />
              </v-col>
              <v-col class="pl-sm-4 px-0" cols="12" sm="6">
                <v-file-input
                  :error-messages="documentoUsuarioError"
                  @blur="$v.documentoUsuario.$touch()"
                  @input="$v.documentoUsuario.$touch()"
                  accept="application/pdf"
                  clearable
                  label="Comprobante de nombramiento *"
                  outlined
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  v-model="documentoUsuario"
                />
              </v-col>
              <v-col class="pr-sm-4 px-0" cols="12" sm="6">
                <v-autocomplete
                  v-if="verifyRolDireccion"
                  :error-messages="institucionUsuarioError"
                  :items="ctlInstituciones"
                  @blur="$v.institucionUsuario.$touch()"
                  @input="$v.institucionUsuario.$touch()"
                  clearable
                  item-text="nombre"
                  item-value="id"
                  label="Institución *"
                  no-data-text="Sin datos"
                  outlined
                  return-object
                  v-model="institucionUsuario"
                />
              </v-col>
              <v-col cols="0" sm="6" />
              <v-col class="pr-sm-4 px-0" cols="12" sm="6">
                <v-autocomplete
                  :error-messages="rolPerfilUsuarioError"
                  :disabled="!!perfilRolPreSeleccionado"
                  :items="ctlRolesPerfiles"
                  @blur="$v.rolPerfilUsuario.$touch()"
                  @input="$v.rolPerfilUsuario.$touch()"
                  @change="verificarJefes"
                  clearable
                  item-text="nombre"
                  item-value="id"
                  label="Rol o perfil *"
                  no-data-text="Sin datos"
                  outlined
                  return-object
                  v-model="rolPerfilUsuario"
                />
              </v-col>
              <v-col class="pl-sm-4 px-0" cols="12" sm="6">
                <vc-date-picker
                  v-model="fechaCaducidadUsuario"
                  mode="date"
                  locale="es"
                  :popover="config_calendar"
                  :model-config="modelConfig"
                  :min-date="minDate"
                >
                  <template v-slot="{ inputEvents }">
                    <v-text-field
                      :error-messages="fechaCaducidadUsuarioError"
                      :value="fechaCaducidadUsuario"
                      @blur="$v.fechaCaducidadUsuario.$touch()"
                      class="border roundend focus:outline-none focus:border-blue-300"
                      label="Fecha de caducidad *"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-on="inputEvents"
                    />
                  </template>
                </vc-date-picker>
              </v-col>
              <v-col v-if="perfilRolPreSeleccionado == 30" class="pr-sm-4 px-0" cols="12" sm="6">
                <v-autocomplete
                  :error-messages="regionError"
                  :items="ctlRegiones"
                  @blur="$v.region.$touch()"
                  @input="$v.region.$touch()"
                  clearable
                  item-text="nombre"
                  label="Región a la que estará asignado *"
                  no-data-text="Sin datos"
                  outlined
                  placeholder="Seleccione"
                  v-model="region"
                  return-object
                  @change="fetchPaises"
                />
              </v-col>
              <v-col v-if="perfilRolPreSeleccionado == 30" class="pl-sm-4 px-0" cols="12" sm="6">
                <v-autocomplete
                  :error-messages="paisError"
                  :items="ctlPaises"
                  @blur="$v.pais.$touch()"
                  @input="$v.pais.$touch()"
                  clearable
                  item-text="nombre"
                  label="País al que estará asignado *"
                  no-data-text="Sin datos"
                  outlined
                  placeholder="Seleccione"
                  v-model="pais"
                  return-object
                  :disabled="region == null"
                />
              </v-col>
              <v-col class="pr-sm-4 px-0" cols="12" sm="6">
                <v-text-field
                  :error-messages="cargoUsuarioError"
                  @blur="$v.cargoUsuario.$touch()"
                  @input="$v.cargoUsuario.$touch()"
                  clearable
                  label="Cargo funcional *"
                  maxLength="75"
                  outlined
                  v-model="cargoUsuario"
                />
              </v-col>
              <v-col class="pl-sm-4 px-0" cols="12" sm="6">
                <v-autocomplete
                  :error-messages="unidadUsuarioError"
                  :items="ctlUnidades"
                  @blur="$v.unidadUsuario.$touch()"
                  @input="$v.unidadUsuario.$touch()"
                  @change="verificarJefes"
                  clearable
                  item-value="id"
                  label="División, Unidad, Departamento o Área a la que pertenece *"
                  no-data-text="Sin datos"
                  :item-text="item =>`${item?.nombre} - (${item?.institucion})`"
                  outlined
                  return-object
                  v-model="unidadUsuario"
                  :disabled="verifyDUDA"
                >
                  <template v-slot:item="{ item }">
                    <div>
                      <span>{{ item.nombre }}</span> - <span class="primary--text font-weight-bold">{{ item.institucion }}</span>
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col class="pr-sm-4 px-0" cols="12" sm="6">
                <v-btn
                  v-if="verifyMoreThanOneUnidad && !verifyDUDA"
                  :block="$vuetify.breakpoint.xs"
                  :disabled="unidadUsuario == null"
                  color="btnMinsal"
                  dense
                  @click="addUnidad"
                >
                  <v-icon class="bgWhite--text"> mdi-plus </v-icon>
                </v-btn>
              </v-col>
              <v-col class="px-0" cols="12">
                <v-textarea
                  :error-messages="observacionesUsuarioError"
                  @blur="$v.observacionesUsuario.$touch()"
                  @input="$v.observacionesUsuario.$touch()"
                  auto-grow
                  clearable
                  counter="500"
                  label="Observaciones"
                  maxLength="500"
                  outlined
                  row-height="30"
                  rows="4"
                  v-model.trim="observacionesUsuario"
                />
              </v-col>
              <v-col
                class="pr-sm-4 px-0"
                cols="12"
                v-if="verifyMoreThanOneUnidad && !verifyDUDA"
              >
                <v-data-table
                  :headers="headers"
                  :items="stckUnidadUsuario"
                  class="elevation-1 mt-5"
                  hide-default-footer
                  disable-pagination
                >
                  <template #[`item.actions`]="{ item }">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          color="btnMinsal"
                          dark
                          @click="deleteUnidad(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-row justify="center" class="mt-4">
          <v-col cols="12" lg="6">
            <div class="d-flex align-center flex-wrap-reverse justify-end" style="gap: 16px; width: 100%">
              <v-btn
              color="primary"
              class="flex-grow-1 flex-shrink-1"
              style="min-width: 200px;"
              x-large
              outlined
              @click="$router.push({ name: 'listado-usuarios-solicitudes' })"
            >
              Cancelar
            </v-btn>

              <v-btn
                color="primary"
                class="flex-grow-1 flex-shrink-1"
                style="min-width: 200px;"
                dark
                x-large
                @click="validateForm"
                :disabled="btnSolicitar"
              >
                Crear usuario
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <ConfirmationDialogComponent
          :show="modalConfirmar"
          btnConfirmar="Aceptar"
          title="¿Desea crear el usuario?"
          message="De confirmarse la siguiente acción, los cambios no serán reversibles."
          @close="modalConfirmar = false"
          @confirm="createUsuario"
        />
    </section>
</template>
<script>
import { mapState } from "vuex";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";
import FormMainCuentaComponent from './FormMainCuentaComponent.vue';
import LegacyValidations from "@/utils/legacy-validations";
import {
  required,
  email,
  minLength,
  requiredIf,
  numeric,
  helpers,
} from "vuelidate/lib/validators";
import moment from "moment";
import { isNil } from "lodash";

const letter = helpers.regex("letter", /^[a-zA-ZÀ-ÿ\u00f1\u00d1'. ]*(-?[a-zA-ZÀ-ÿ\u00f1\u00d1'. ]+)?$/);
const txtField = helpers.regex("txtField", /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ -]*$/);
const txtArea = helpers.regex(
  "txtArea",
  /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 ()/.,\n:;-]*$/
);

function containNumber(value) {
  if (value) {
    if (
      value.charAt(0) == "2" ||
      value.charAt(0) == "6" ||
      value.charAt(0) == "7"
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}
function validCantidadNumero(value) {
  if(!value) return true
  if(this.nacionalidad?.id_tipo_documento !== 1 || this.nacionalidad?.id_tipo_documento !== 2) {
    return value.length > 1
  }
}

function typeDui(value) {
  if (value && this.nacionalidad?.id_tipo_documento) {
    const regexDocument = /^[0-9]*$/;
    if (this.nacionalidad?.id_tipo_documento === 1) {
      return this.isDui(value);
    } else if (this.nacionalidad?.id_tipo_documento === 2) {
      return this.isNit(value);
    } else {
      return regexDocument.test(value);
    }
  } else {
    return true;
  }
}

export default {
  name: "CreacionUsuario",
  emits: ['on-title-load'],
  components: {
    FormMainCuentaComponent,
    ConfirmationDialogComponent,
  },
  props: {
    mostrarTitulo: { type: Boolean, default: true },
    perfilRolPreSeleccionado: { type: [Number, String] }
  },
  validations: {
    nacionalidad: {
      required,
    },
    numeroDocumento: {
      required,
      typeDui,
      validCantidadNumero,
    },
    primerNombre: {
      required,
      letter,
    },
    segundoNombre: {
      letter,
    },
    primerApellido: {
      required,
      letter,
    },
    segundoApellido: {
      letter,
    },
    apellidoCasada: {
      letter,
    },
    emailUsuario: {
      required,
      email,
    },
    telefonoUsuario: {
      required,
      numeric,
      containNumber,
      minLength: minLength(8),
    },
    profesionUsuario: {
      required,
    },
    documentoUsuario: {
      required,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
    institucionUsuario: {
      required: requiredIf(function (value) {
        if (this.verifyRolDireccion) {
          return true;
        } else {
          return false;
        }
      }),
    },
    rolPerfilUsuario: {
      required,
    },
    fechaCaducidadUsuario: {
      required,
    },
    cargoUsuario: {
      required,
      txtField,
    },
    observacionesUsuario: {
      txtArea,
    },
    unidadUsuario: {
      required: requiredIf(function (value) {
        if (value?.stckUnidadUsuario?.length > 1) {
          return false;
        } else {
          if (value?.unidadUsuario == null) {
            return true;
          } else {
            return false;
          }
        }
      }),
    },
    region: {
      required: requiredIf(function (value) {
        if (this.perfilRolPreSeleccionado != 30) return false;
        return true;
      }),
    },
    pais: {
      required: requiredIf(function (value) {
        if (this.perfilRolPreSeleccionado != 30) return false;
        return true;
      }),
    }
  },
  data: () => ({
    // info general
    apellidoCasada: null,
    nacionalidad: null,
    numeroDocumento: null,
    primerApellido: null,
    primerNombre: null,
    segundoApellido: null,
    segundoNombre: null,
    region: null,
    pais: null,
    // info cuenta
    emailUsuario: null,
    telefonoUsuario: null,
    profesionUsuario: null,
    documentoUsuario: null,
    institucionUsuario: null,
    rolPerfilUsuario: null,
    fechaCaducidadUsuario: null,
    cargoUsuario: null,
    observacionesUsuario: null,
    unidadUsuario: null,
    stckUnidadUsuario: [],
    // catalogos
    ctlNacionalidad: [],
    ctlProfesiones: [],
    ctlRolesPerfiles: [],
    ctlUnidades: [],
    ctlInstituciones: [],
    ctlRegiones: [],
    ctlPaises: [],
    // otros
    mask: "########-#",
    verifyDUDA: false,
    modalConfirmar: false,
    docMxLength: null,
    showInput: false,
    headers: [
      {
        text: "Nombre",
        value: "nombre",
      },
      { text: "Eliminar", value: "actions", sortable: false },
    ],
    modelConfig: {
      type: "string",
      mask: "DD/MM/YYYY",
    },
    config_calendar: {
      visibility: "click",
    },
    btnSolicitar: false,
  }),
  computed: {
    ...mapState(["selectedUnidad"]),
    verifyRolDireccion() {
      return this.haveRole("ROLE_DIRECCION_COMPRAS_CREAR_USUARIO");
    },
    verifyRolTitular() {
      return this.haveRole("ROLE_TITULAR_ESTABLECIMIENTO");
    },
    verifyNacionalidad() {
      if (
        !this.$v.nacionalidad.$invalid &&
        this.nacionalidad?.id_tipo_documento != 1
      ) {
        this.showInput = true;
        return false;
      } else {
        return true;
      }
    },
    minDate() {
      return moment().add(2, "days").format("YYYY-MM-DD");
    },
    verifyMoreThanOneUnidad() {
      // return [1, 7].includes(this.rolPerfilUsuario?.id);
      return false;
    },
    // Error messages
    nacionalidadError() {
      const errors = [];
      if (!this.$v.nacionalidad.$dirty) return errors;
      !this.$v.nacionalidad.required &&
        errors.push("Nacionalidad es requerido");
      return errors;
    },
    numeroDocumentoError() {
      const errors = [];
      if (!this.$v.numeroDocumento.$dirty) return errors;
      !this.$v.numeroDocumento.required &&
        errors.push("Número de documento es requerido");
      !this.$v.numeroDocumento.typeDui &&
        errors.push("Número de documento no es válido");
      !this.$v.numeroDocumento.validCantidadNumero &&
        errors.push("Longitud del número de documento debe ser mayor a 1");
      return errors;
    },
    primerNombreError() {
      const errors = [];
      if (!this.$v.primerNombre.$dirty) return errors;
      !this.$v.primerNombre.required &&
        errors.push("Primer nombre es requerido");
      !this.$v.primerNombre.letter && errors.push("Primer nombre no es válido");
      return errors;
    },
    segundoNombreError() {
      const errors = [];
      if (!this.$v.segundoNombre.$dirty) return errors;
      !this.$v.segundoNombre.letter &&
        errors.push("Segundo nombre no es válido");
      return errors;
    },
    primerApellidoError() {
      const errors = [];
      if (!this.$v.primerApellido.$dirty) return errors;
      !this.$v.primerApellido.required &&
        errors.push("Primer apellido es requerido");
      !this.$v.primerApellido.letter &&
        errors.push("Primer apellido no es válido");
      return errors;
    },
    segundoApellidoError() {
      const errors = [];
      if (!this.$v.segundoApellido.$dirty) return errors;
      !this.$v.segundoApellido.letter &&
        errors.push("Segundo apellido no es válido");
      return errors;
    },
    apellidoCasadaError() {
      const errors = [];
      if (!this.$v.apellidoCasada.$dirty) return errors;
      !this.$v.apellidoCasada.letter &&
        errors.push("Apellido de casada no es válido");
      return errors;
    },
    emailUsuarioError() {
      const errors = [];
      if (!this.$v.emailUsuario.$dirty) return errors;
      !this.$v.emailUsuario.required &&
        errors.push("Correo electrónico es requerido.");
      !this.$v.emailUsuario.email &&
        errors.push("Correo electrónico no es válido.");
      return errors;
    },
    telefonoUsuarioError() {
      const errors = [];
      if (!this.$v.telefonoUsuario.$dirty) return errors;
      !this.$v.telefonoUsuario.required &&
        errors.push("Teléfono es requerido.");
      !this.$v.telefonoUsuario.numeric && errors.push("Teléfono no es válido.");
      !this.$v.telefonoUsuario.containNumber &&
        errors.push("Teléfono debe comenzar con 2, 6 o 7.");
      !this.$v.telefonoUsuario.minLength &&
        errors.push("Teléfono debe tener al menos 8 dígitos.");
      return errors;
    },
    profesionUsuarioError() {
      const errors = [];
      if (!this.$v.profesionUsuario.$dirty) return errors;
      !this.$v.profesionUsuario.required &&
        errors.push("Profesión es requerido.");
      return errors;
    },
    documentoUsuarioError() {
      const errors = [];
      if (!this.$v.documentoUsuario.$dirty) return errors;
      !this.$v.documentoUsuario.required &&
        errors.push("Comprobante de nombramiento es requerido");
      !this.$v.documentoUsuario.fileNameValid &&
        errors.push("El archivo debe ser de tipo PDF.");
      !this.$v.documentoUsuario.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);
      return errors;
    },
    institucionUsuarioError() {
      const errors = [];
      if (!this.$v.institucionUsuario.$dirty) return errors;
      !this.$v.institucionUsuario.required &&
        errors.push("Institución es requerido.");
      return errors;
    },
    rolPerfilUsuarioError() {
      const errors = [];
      if (!this.$v.rolPerfilUsuario.$dirty) return errors;
      !this.$v.rolPerfilUsuario.required &&
        errors.push("Rol/Perfil es requerido.");
      return errors;
    },
    fechaCaducidadUsuarioError() {
      const errors = [];
      if (!this.$v.fechaCaducidadUsuario.$dirty) return errors;
      !this.$v.fechaCaducidadUsuario.required &&
        errors.push("Fecha de caducidad es requerido.");
      return errors;
    },
    cargoUsuarioError() {
      const errors = [];
      if (!this.$v.cargoUsuario.$dirty) return errors;
      !this.$v.cargoUsuario.required && errors.push("Cargo es requerido.");
      !this.$v.cargoUsuario.txtField && errors.push("No utilice números ni caracteres especiales en este campo.");
      return errors;
    },
    observacionesUsuarioError() {
      const errors = [];
      if (!this.$v.observacionesUsuario.$dirty) return errors;
      !this.$v.observacionesUsuario.txtArea &&
        errors.push("Observaciones no es válido.");
      return errors;
    },
    unidadUsuarioError() {
      const errors = [];
      if (!this.$v.unidadUsuario.$dirty) return errors;

      if (
        !(this.verifyMoreThanOneUnidad && !this.verifyDUDA) &&
        this.unidadUsuario == null
      )
        errors.push("Unidad es requerida.");
      if (
        this.verifyMoreThanOneUnidad &&
        !this.verifyDUDA &&
        this.stckUnidadUsuario.length == 0
      )
        errors.push("Unidad es requerida.");
      return errors;
    },
    regionError() {
      const errors = [];
      if (!this.$v.region.$dirty) return errors;
      !this.$v.region.required && errors.push("Región es requerida.");
      return errors;
    },
    paisError() {
      const errors = [];
      if (!this.$v.pais.$dirty) return errors;
      !this.$v.pais.required && errors.push("País es requerido.");
      return errors;
    },
  },
  methods: {
    addUnidad() {
      if (this.stckUnidadUsuario?.length > 0) {
        if (
          this.stckUnidadUsuario?.find(
            (item) => item.id === this.unidadUsuario?.id
          )
        ) {
          this.temporalAlert({
            show: true,
            type: "warning",
            message: "no puede agregar la misma unidad",
          });
          return;
        } else {
          this.ctlUnidades = this.ctlUnidades.filter(
            (item) => item.id !== this.unidadUsuario.id
          );

          this.stckUnidadUsuario.push(this.unidadUsuario);
          this.unidadUsuario = null;
        }
      } else {
        this.ctlUnidades = this.ctlUnidades.filter(
          (item) => item.id !== this.unidadUsuario.id
        );

        this.stckUnidadUsuario.push(this.unidadUsuario);
        this.unidadUsuario = null;
        return;
      }
    },
    deleteUnidad(value) {
      this.stckUnidadUsuario = this.stckUnidadUsuario.filter(
        (item) => item.id !== value.id
      );
      this.ctlUnidades.push(value);
    },
    validateForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.temporalAlert({
          show: true,
          type: "error",
          message: "Debe completar todos los campos requeridos",
        });
      } else {
        this.modalConfirmar = true;
      }
    },
    checkNumeroDocumento() {
      this.primerNombre = null;
      this.segundoNombre = null;
      this.primerApellido = null;
      this.segundoApellido = null;
      this.apellidoCasada = null;
    },
    async consultarPersona() {
      if (
        this.nacionalidad?.id_tipo_documento == 1 &&
        !this.$v.numeroDocumento.$invalid
      ) {

        try {
          const response =
            await this.services.VerificarDocumento.verificarDocumento(
              this.numeroDocumento,
              { crear_usuario: true }
            );
          if (response) {
            this.primerNombre = response?.data?.primer_nombre;
            this.segundoNombre = response?.data?.segundo_nombre;
            this.primerApellido = response?.data?.primer_apellido;
            this.segundoApellido = response?.data?.segundo_apellido;
            if (response?.data?.id_genero == 1) {
              this.apellidoCasada = null;
              this.showInput = false;
            } else {
              this.showInput = true;
              this.apellidoCasada = response?.data?.apellido_casada;
            }
          }
        } catch (error) {
          console.log(error);
        }

      } else {
        return;
      }
    },
    async consultarUsuario() {
      if (this.numeroDocumento != null && this.nacionalidad != null) {
        try {
          const response = await this.services.Usuarios.getUsuarios({
            numero_documento: this.numeroDocumento,
            id_tipo_documento: this.nacionalidad?.id,
            crear_usuario: true,
          });
          if (response) {
            if (
              this.haveRoles([
                "ROLE_DIRECCION_COMPRAS",
                "ROLE_TECNICO_DIRECCION_COMPRAS",
                "ROLE_TITULAR_ESTABLECIMIENTO"
              ])
            ) {
              this.temporalAlert({
                show: true,
                type: "info",
                message: "Documento apto para crear cuenta",
              });
              if (this.nacionalidad?.id_tipo_documento == 1) {
                await this.consultarPersona();
              }
            } else {
              if (
                response?.data?.length == 0 ||
                response?.data[0]?.id_usuario == null
              ) {
                this.temporalAlert({
                  show: true,
                  type: "info",
                  message: "Documento apto para crear cuenta",
                });
                if (this.nacionalidad?.id_tipo_documento == 1) {
                  await this.consultarPersona();
                }
              } else {
                this.temporalAlert({
                  show: true,
                  type: "error",
                  message:
                    "El documento ingresado ya se encuentra registrado en el sistema",
                });
                this.$router.push({ name: "listado-usuarios-solicitudes" });
              }
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    async obtainUrlDocumento() {
      try {
        let formData = new FormData();
        formData.append("documento_cargo", this.documentoUsuario);
        const response = await this.services.Usuarios.postArchivo(formData);
        if (response) {
          return response?.data?.url_documento_cargo;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async createUsuario() {

      // ->
      if (this.stckUnidadUsuario.length == 0 && this.unidadUsuario != null) {
        this.stckUnidadUsuario.push(this.unidadUsuario);
      } else if (
        this.stckUnidadUsuario.length == 1 &&
        this.unidadUsuario != null
      ) {
        if (this.stckUnidadUsuario[0].id != this.unidadUsuario?.id) {
          this.stckUnidadUsuario.push(this.unidadUsuario);
        }
      }
      // <-
      let urlArchivo = null;
      urlArchivo = await this.obtainUrlDocumento();

      if (urlArchivo == null) {
        this.temporalAlert({
          show: true,
          type: "error",
          message: "No se pudo subir el archivo",
        });

        return;
      }
      let params = {
        nacionalidad: this.nacionalidad?.id_tipo_documento,
        primer_nombre: this.primerNombre,
        segundo_nombre: this.segundoNombre ? this.segundoNombre : undefined,
        primer_apellido: this.primerApellido,
        segundo_apellido: this.segundoApellido
          ? this.segundoApellido
          : undefined,
        apellido_casada: this.apellidoCasada ? this.apellidoCasada : undefined,
        fecha_caducidad: this.moment(
          this.fechaCaducidadUsuario,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD HH:mm:ss"),
        cargo_funcional: this.cargoUsuario,
        informacion_adicional: this.observacionesUsuario
          ? this.observacionesUsuario
          : undefined,
        profesion: this.profesionUsuario?.id,
        rol_perfil: this.rolPerfilUsuario?.id,
        numero_documento: this.numeroDocumento,
        correo_electronico: this.emailUsuario,
        telefono: this.telefonoUsuario,
        url_documento_cargo: urlArchivo,
        unidades: this.stckUnidadUsuario,
        // institucion: this.institucionUsuario?.id, // se deberia de agregar ?
        id_pais: this.pais?.id,
      };
      try {
        const response = await this.services.Usuarios.postUsuario(params);
        if (response) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: response?.data?.message || "Usuario creado correctamente",
          });
          this.$router.push({ name: "listado-usuarios-solicitudes" });
        }
      } catch (error) {
        console.log(error);
      }

    },
    async fetchNacionalidad() {
      try {
        const response =
          await this.services.IdentificadorFiscal.getIdentificadorFiscal();
        if (response) {
          this.ctlNacionalidad = response.data.filter((item) =>
            [1, 6].includes(item.id)
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchRegiones() {
      try {
        const response =
          await this.services.Paises.getListaRegiones();
        if (response) {
          this.ctlRegiones = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchPaises(idRegion) {
      try {
        this.pais = null;
        const response =
          await this.services.Paises.getListaPaises({
            region: idRegion?.id,
          });
        if (response) {
          this.ctlPaises = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchProfesiones() {
      try {
        const response = await this.services.Usuarios.getProfesiones();
        if (response) {
          this.ctlProfesiones = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchRolesPerfiles() {
      try {
        const response = await this.services.Usuarios.getPerfilRolDependencia();

        if (response) this.ctlRolesPerfiles = response.data;

        if (!isNil(this.perfilRolPreSeleccionado)) {
          this.rolPerfilUsuario = response.data.find((rol) => rol.id === this.perfilRolPreSeleccionado);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchDUDA(id_institucion = null) {
      try {
        if (
          this.haveRole("ROLE_TITULAR_ESTABLECIMIENTO") &&
          !this.haveRole("ROLE_DIRECCION_COMPRAS")
        )
          id_institucion = this.selectedUnidad.instituciones.id;

        let response;
        if (
          (this.verifyRolDireccion || this.verifyRolTitular) &&
          id_institucion
        ) {
          response = await this.services.Usuarios.getDUDA();
        } else {
          response = await this.services.Usuarios.getDUDAEmpleado();
        }
        if (response) {
          if (response.data.length === 1) {
            this.verifyDUDA = true;
            this.unidadUsuario = response.data[0];
          } else {
            this.verifyDUDA = false;
          }
          this.ctlUnidades = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchInstituciones() {
      if (this.verifyRolDireccion) {
        try {
          const response = await this.services.Usuarios.getInstituciones();
          if (response) {
            this.ctlInstituciones = response.data;
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        return;
      }
    },
    async verificarJefes() {
      if (
        this.unidadUsuario?.id &&
        this.rolPerfilUsuario?.id &&
        [4, 6, 11].includes(this.rolPerfilUsuario?.id)
      ) {
        try {
          const response = await this.services.SolicitudesUsuarios.validarJefes(
            {
              id_unidad: this.unidadUsuario?.id,
            }
          );
          if (response?.data?.id_usuario > 0) {
            this.btnSolicitar = true;
            this.temporalAlert({
              show: true,
              type: "warning",
              message: `Ya existe un jefe en la unidad: ${this.unidadUsuario?.nombre}`,
            });
          } else {
            this.btnSolicitar = false;
          }
        } catch (error) {
          this.temporalAlert({
            show: true,
            type: "error",
            message:
              "No se ha podido validar la existencia de un jefe para esta unidad",
          });
        }
      } else {
        this.btnSolicitar = false;
      }
    },
  },
  watch: {
    nacionalidad: function (val) {
      if (val?.id_tipo_documento === 1) {
        this.mask = "########-#";
        this.docMxLength = 10;
      } else if (val?.id_tipo_documento === 2) {
        this.mask = "####-######-###-#";
        this.docMxLength = 17;
      } else {
        this.mask = "";
        this.docMxLength = 15;
      }
    },
    numeroDocumento: function (val) {
      if (val == null || val == "") {
        this.checkNumeroDocumento();
      }
    },
    rolPerfilUsuario: function (val) {
      if (val) {
        if (val.id == 8) {
          // HACK: el find se hace con un nombre porque el id varia segun la institucion, favor de revisar
          this.unidadUsuario = this.ctlUnidades.find(
            (item) => item.nombre == "DESPACHO"
          );
        }
        if (val.id != 7) {
          this.stckUnidadUsuario = [];
        }
      }
    },
    institucionUsuario: function (val) {
      if (val) {
        if (this.rolPerfilUsuario?.id == 7) this.stckUnidadUsuario = [];
        this.unidadUsuario = null;
        this.fetchDUDA(this.institucionUsuario.id);
      } else {
        if (this.rolPerfilUsuario?.id == 7) this.stckUnidadUsuario = [];
        this.unidadUsuario = null;
        this.ctlUnidades = [];
      }
    },
    perfilRolPreSeleccionado: {
      handler(value) {
        if (value) {
          this.rolPerfilUsuario = this.ctlRolesPerfiles.find((rol) => rol.id === value);
        }
      },
      immediate: true,
    },
  },
  beforeCreate() {
    if (this.$store.state?.selectedUnidad?.instituciones) {
      if (
        this.haveRole("ROLE_TITULAR_ESTABLECIMIENTO") &&
        [3, null].includes(
          this.$store.state.selectedUnidad.instituciones.id_nivel_aprobacion
        )
      ) {
        this.$router.push({ name: "dashboard" });
      } else {
        return;
      }
    }
  },
  created() {
    this.$emit('on-title-load', 'Creación de usuario');
    this.fetchNacionalidad();
    this.fetchRegiones();
    this.fetchProfesiones();
    this.fetchRolesPerfiles();
    this.fetchInstituciones();
    if (
      !this.haveRole("ROLE_DIRECCION_COMPRAS") &&
      !this.haveRole("ROLE_TECNICO_DIRECCION_COMPRAS")
    ) {
      this.fetchDUDA();
    }
  },
};
</script>
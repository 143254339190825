<template>
  <v-container>
    <v-card>
      <v-card-title
        class="align-center text-subtitle-1 text-uppercase"
        style="gap: 8px"
      >
        <NavButton />
        <h1 style="word-break: break-word">
          {{ tituloDinamico }}
        </h1>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row justify="center" no-gutters dense>
          <v-col cols="12" md="8" lg="6">
            <v-select
              v-model="perfil_a_crear"
              :items="perfiles.data"
              hide-details
              :loading="perfiles.isLoading"
              outlined
              item-text="nombre"
              item-value="id"
              label="Rol o perfil *"
              placeholder="Seleccione un rol o perfil"
              class="mb-4"
              clearable
              @click:clear="titulo_pagina = null"
            />
            <v-alert v-if="!perfil_a_crear" dense type="info" class="mb-0">
              Seleccione un rol o perfil para continuar
            </v-alert>
          </v-col>
        </v-row>
        <template v-if="mostrar_formulario">
          <CrearSolicitudUsuario
            v-if="mostrarCreacionDeSolicitud"
            modo="creacion"
            :mostrar-titulo="false"
            :perfil-rol-pre-seleccionado="perfil_a_crear"
            @on-title-load="establecerTituloPagina"
          />
          <CreacionUsuario
            v-else
            :mostrar-titulo="false"
            @on-title-load="establecerTituloPagina"
            :perfil-rol-pre-seleccionado="perfil_a_crear"
          />
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {
  createLoadable,
  setLoadableResponse,
  toggleLoadable,
} from "@/utils/loadable";
import CrearSolicitudUsuario from "./components/CrearSolicitudUsuario.vue";
import CreacionUsuario from "./components/CreacionUsuario.vue";
import { Fragment } from "vue-fragment";
import NavButton from "@/components/utils/NavButton.vue";
import { isNil } from "lodash";

export default {
  name: "creacionUsuarioView",
  components: { CrearSolicitudUsuario, CreacionUsuario, Fragment, NavButton },
  data: () => ({
    perfiles: createLoadable([]),
    perfil_a_crear: null,
    titulo_pagina: null,
    mostrar_formulario: false,
  }),
  computed: {
    mostrarCreacionDeSolicitud() {
      return [20, 34].includes(this.perfil_a_crear);
    },
    tituloDinamico() {
      return this.titulo_pagina ?? "Gestión de usuarios";
    },
  },
  methods: {
    async cargarPerfiles() {
      try {
        toggleLoadable(this.perfiles);
        const response = await this.services.Usuarios.getPerfilRolDependencia();

        setLoadableResponse(this.perfiles, [], { skipOnSuccess: true });
        this.perfiles.data = response.data;
      } catch (error) {
        this.perfiles.error = {
          message: error.message ?? "Error al cargar los perfiles",
        };
        console.log(error);
      } finally {
        this.perfiles.isLoading = false;
      }
    },
    establecerTituloPagina(titulo) {
      this.titulo_pagina = titulo;
    },
  },
  watch: {
    perfil_a_crear: {
      handler(value) {
        this.mostrar_formulario = false;

        // Limpiando formulario ya que el estado de los sub componentes no se encuentra en vuex
        setTimeout(() => {
          this.mostrar_formulario = !isNil(value);
        }, 0);
      },
    },
  },
  created() {
    this.cargarPerfiles();
  },
};
</script>
